import { CandlestickChart, FilePieChart, LayoutDashboard, Zap } from 'lucide-react'
import Link from 'next/link'
import { ReactNode } from 'react'

interface BenefitCardProps {
  title: string
  description: string
  CustomComponent?: ReactNode
}

const BenefitCard: React.FC<BenefitCardProps> = ({ title, description, CustomComponent }) => {
  return (
    <div className="mx-auto w-full border border-zinc-200 rounded-lg py-4 px-10 grid gap-4">
      <div className="mt-6">
        {CustomComponent && (
          <div className="custom-component mb-6 bg-orange-100 text-orange-600 p-2 rounded-full w-fit">
            {CustomComponent}
          </div>
        )}
        <h3 className="font-semibold text-sm mb-2 text-zinc-900">{title}</h3>
        <div className="grid gap-2 mb-6">
          <p className="text-sm">{description}</p>
        </div>
      </div>
    </div>
  )
}

export const Benefits = () => {
  return (
    <>
      <section className="px-1 w-full md:w-9/12 mx-auto mt-20">
        <div className="text-center mx-auto w-full grid gap-4 mb-6">
          <p className="text-lg md:text-2xl font-semibold text-zinc-900">Benefits</p>
          <h2 className="text-md text-zinc-800">
            Opinly's Competitor Analysis Software helps businesses stay ahead of the <br />
            market with real-time updates on your competitors' activities.
          </h2>
        </div>
        <div className="mt-8 mx-auto ">
          <div className="grid md:grid-cols-2 gap-6 w-full p-4">
            <BenefitCard
              title="Market Insights"
              description="Dive deep into market trends, consumer behaviors, and competitor strategies."
              CustomComponent={<CandlestickChart className="w-7 h-7" />}
            />

            <BenefitCard
              title="Customizable Analysis"
              description="Tailor your analysis to focus on specific competitor features and operations."
              CustomComponent={<LayoutDashboard className="w-7 h-7" />}
            />

            <BenefitCard
              title="Seamless Integration"
              description="Integrate with existing business tools for enhanced insights and decision-making."
              CustomComponent={<Zap className="w-7 h-7" />}
            />

            <BenefitCard
              title="Real-Time Data"
              description="Get updates on competitors’ website traffic and performance."
              CustomComponent={<FilePieChart className="w-7 h-7" />}
            />
          </div>
        </div>
      </section>
    </>
  )
}
