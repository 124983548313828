import { GoogleGeminiEffect } from '@/components/ui/aceternity/google-gemini-effect'
import { useScroll, useTransform } from 'framer-motion'
import Image from 'next/image'
import Link from 'next/link'
import React from 'react'
import { useInView } from 'react-intersection-observer'

interface ToolCardProps {
  imageSrc: string
  title: string
  description: string
  linkAddress?: string
}

const ToolCard: React.FC<ToolCardProps> = ({ imageSrc, title, description, linkAddress }) => {
  return (
    <div className="mx-auto w-full border border-zinc-200 rounded-lg p-10 grid gap-4">
      <Image
        className="w-full flex justify-center items-center object-scale-down"
        priority={true}
        src={imageSrc}
        alt="Logo"
        width={1000}
        height={1000}
      />
      <div className="mt-6">
        <h3 className="font-semibold text-md mb-2 text-zinc-900">{title}</h3>
        <div className="grid gap-2 mb-6">
          <p className="text-md">{description}</p>
        </div>
        {linkAddress && (
          <Link
            href={linkAddress}
            className="inline-flex items-center justify-center whitespace-nowrap rounded-lg text-xs font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 px-0 mx-0 m-0 text-zinc-800 underline-offset-4 hover:underline p-0 text-left"
          >
            Learn more
          </Link>
        )}
      </div>
    </div>
  )
}

export default ToolCard

export const KeyFeatures = () => {
  const [inViewRef] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

  const ref = React.useRef(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ['start start', 'end start'],
  })

  const pathLengthFirst = useTransform(scrollYProgress, [0, 0.8], [0.2, 1.2])
  const pathLengthSecond = useTransform(scrollYProgress, [0, 0.8], [0.15, 1.2])
  const pathLengthThird = useTransform(scrollYProgress, [0, 0.8], [0.1, 1.2])
  const pathLengthFourth = useTransform(scrollYProgress, [0, 0.8], [0.05, 1.2])
  const pathLengthFifth = useTransform(scrollYProgress, [0, 0.8], [0, 1.2])

  return (
    <>
      <div
        className="h-[120vh] w-full rounded-md relative pb-40 overflow-clip hidden sm:block"
        ref={ref}
      >
        <GoogleGeminiEffect
          pathLengths={[
            pathLengthFirst,
            pathLengthSecond,
            pathLengthThird,
            pathLengthFourth,
            pathLengthFifth,
          ]}
        />
      </div>
      <section className="mt-36 md:mt-24 mx-auto w-full">
        <div className="sticky px-1 w-full md:w-9/12 mx-auto p-4 md:p-12 h-fit">
          <div className="text-center mx-auto w-full grid gap-4 mb-6">
            <p className="text-lg md:text-2xl font-semibold text-zinc-900">Key Features</p>
            <h2 className="text-md text-zinc-800">
              Dominate Your Market using Opinly's Competitive Research Analysis Tool
            </h2>
          </div>
          <div className="mt-6 font-normal pb-4 bg-clip-text text-transparent bg-gradient-to-b from-zinc-600 to-zinc-500 mx-auto grid gap-8">
            <div className="grid md:grid-cols-2 gap-8 w-full p-4">
              <ToolCard
                imageSrc="/files/landing/price.svg"
                title="Competitive Price Analysis"
                description="Opinly offers comprehensive price analysis services, helping businesses stay ahead by analyzing their competitors' websites and pricing strategies effectively."
                linkAddress="/tools/competitive-price-analysis"
              />

              <ToolCard
                imageSrc="/files/landing/product.svg"
                title="Product Comparison Tool"
                description="Opinly's Product Comparison Tool provides analysis of your competitor's product, helping your business make informed decisions by analyzing their updates on product features and reviews across various platforms."
                linkAddress="/tools/product-comparison-tool"
              />

              <ToolCard
                imageSrc="/files/landing/landing-pages.svg"
                title="Landing Pages"
                description="Discover how Opinly helps you to create engaging, competitive landing pages, helping your business gain a competitive edge by highlighting your products or services' strengths and unique selling points on your website landing page."
                linkAddress="/tools/competitor-landing-page-comparison"
              />

              <ToolCard
                imageSrc="/files/landing/seo.svg"
                title="SEO Monitor"
                description="Opinly's SEO Software Monitor is an all-in-one SEO software that keeps your website on top with real-time SEO monitor insights, a keyword research tool, and competitor insights. It ensures the best practices of organic SEO that provide visibility and growth for your business."
                linkAddress="/tools/seo-monitor"
              />

              <ToolCard
                imageSrc="/files/landing/email.svg"
                title="Competitor Email Monitoring"
                description="Stay ahead with Opinly's Competitor Email Monitoring. Track your competitor’s email marketing campaigns , strategies, and trends to refine your email marketing approach with this email competitor content research tool."
                linkAddress="/tools/competitor-email-monitoring"
              />

              <ToolCard
                imageSrc="/files/landing/dashboard.svg"
                title="Interactive Dashboards"
                description="Visualize complex data with Opinly's Interactive Dashboards. Gain insights into competitor strategies, track key metrics, and make informed decisions with customizable, real-time data visualization tools."
              />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
