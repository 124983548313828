import { Card, CardContent, CardHeader } from '@/components/ui/card'
import { Separator } from '@/components/ui/separator'
import {
  BookKey,
  Brain,
  Check,
  ChevronDown,
  Clock9,
  LayoutDashboard,
  Pencil,
  Rocket,
  Shapes,
  Split,
  Umbrella,
} from 'lucide-react'
import Link from 'next/link'
import { ReactNode } from 'react'
import { Companies } from './companies'
import Image from 'next/image'
import { Button } from '@/components/ui/button'

interface BenefitCardProps {
  title: string
  description: string
  CustomComponent?: ReactNode
}

const ReasonCard: React.FC<BenefitCardProps> = ({ title, description, CustomComponent }) => {
  return (
    <div className="mx-auto w-full rounded-lg grid gap-1">
      <div className="flex items-center">
        <div className="custom-component  bg-green-100 text-green-600 p-1 rounded-full w-fit">
          <Check className="w-5 h-5" />
        </div>
        <h3 className="font-semibold text-md text-zinc-900 ml-3">{title}</h3>
      </div>

      <div className="grid gap-2 mb-6 ml-10">
        <p className="text-xs">{description}</p>
      </div>
    </div>
  )
}

export const WhyChooseOpinly = () => {
  return (
    <>
      <section className="md:w-10/12 mx-auto my-56">
        <div className="text-center mx-auto w-full grid gap-4 mb-6">
          <p className="text-lg md:text-2xl font-semibold text-zinc-900">Why Choose Opinly?</p>
          {/* <h2 className="text-md text-zinc-800">
            Opinly's Competitor Analysis Software helps businesses stay ahead of the <br />
            market with real-time updates on your competitors' activities.{' '}
          </h2> */}
        </div>
        <div className="mt-8 mx-auto grid md:grid-cols-2 gap-12 flex items-center">
          <div className="grid gap-1 w-full p-4">
            <ReasonCard
              title="Enhanced Market Understanding"
              description="Opinly helps businesses understand market dynamics, growth trends, and opportunities, allowing them to compare with competitors and identify niches to exploit."
              CustomComponent={<BookKey className="w-7 h-7" />}
            />

            <ReasonCard
              title="Strategic Decision Making"
              description="Gain insights into competitors’ strategies to make informed decisions on product development, marketing, and pricing, ensuring alignment with market demands."
              CustomComponent={<Split className="w-7 h-7" />}
            />

            <ReasonCard
              title="Product Differentiation"
              description="Understand competitors’ offerings to differentiate your products through new features, improved user experience, or innovative technology."
              CustomComponent={<Shapes className="w-7 h-7" />}
            />

            <ReasonCard
              title="Risk Management"
              description="Identify and address threats from competitors by adjusting strategic plans to mitigate risks associated with market competition."
              CustomComponent={<Umbrella className="w-7 h-7" />}
            />

            <ReasonCard
              title="Customer Insights"
              description="Gather user feedback on competitors’ products to improve your offerings and customer service, leading to higher satisfaction and retention."
              CustomComponent={<Split className="w-7 h-7" />}
            />

            <ReasonCard
              title="Performance Benchmarks"
              description="Use Opinly's benchmarks to measure your company’s performance in sales, market share, customer acquisition, and lifetime value, making necessary adjustments."
              CustomComponent={<Rocket className="w-7 h-7" />}
            />
            <div className="w-fit ml-4 grid gap-1 mt-4">
              <Button
                analyticsKey="why_choose_opinly_cta"
                analyticsProperties={{
                  section: 'public_site',
                }}
                className="w-fit font-semibold"
                size={'lg'}
              >
                <Link href="/auth/signup">Register now</Link>
              </Button>
              <p className="text-zinc-500 text-center mx-auto font-semibold text-xs">
                Try it out for <Link href="/pricing">free</Link> today
              </p>
            </div>
          </div>
          <Image
            className="hidden sm:block w-full flex justify-center items-center object-scale-down"
            src="/files/landing/glow.svg"
            alt="Logo"
            width={1000}
            height={1000}
          />
        </div>
      </section>
    </>
  )
}
