import Image from 'next/image'
import Link from 'next/link'
import { useState } from 'react'

export const Companies = () => {
  const [hoverStates, setHoverStates] = useState({})
  const handleMouseEnter = (src: string) => {
    setHoverStates((prevStates) => ({ ...prevStates, [src]: true }))
  }
  const handleMouseLeave = (src: string) => {
    setHoverStates((prevStates) => ({ ...prevStates, [src]: false }))
  }

  const getImageStyle = (src: string) => ({
    filter: hoverStates[src as keyof typeof hoverStates]
      ? 'none'
      : 'grayscale(100%) brightness(40%)',
    transition: 'filter 0.1s ease',
  })

  return (
    <div className="mx-auto w-full px-2 md:px-0 md:w-8/12 mt-56 hidden sm:block">
      <p className="text-center text-md text-zinc-900 font-medium mb-8">Trusted by</p>
      <div className="grid grid-cols-2 flex items-center gap-8 text-zinc-500 sm:gap-12 md:grid-cols-3 lg:grid-cols-5">
        <Link href="https://www.steppay.kr" target="_blank" rel="noopener noreferrer">
          <Image
            className="mx-auto flex justify-center items-center object-scale-down h-10 w-fit"
            onMouseEnter={() => handleMouseEnter('/files/companies/steppay.webp')}
            onMouseLeave={() => handleMouseLeave('/files/companies/steppay.webp')}
            style={getImageStyle('/files/companies/steppay.webp')}
            src="/files/companies/steppay.webp"
            alt="Logo"
            width={1000}
            height={1000}
          />
        </Link>

        <Link href="https://up.codes/" target="_blank" rel="noopener noreferrer">
          <Image
            className="flex justify-center items-center object-scale-down h-10 w-fit"
            onMouseEnter={() => handleMouseEnter('/files/companies/upcodes.webp')}
            onMouseLeave={() => handleMouseLeave('/files/companies/upcodes.webp')}
            style={getImageStyle('/files/companies/upcodes.webp')}
            src="/files/companies/upcodes.webp"
            alt="Logo"
            width={1000}
            height={1000}
          />
        </Link>

        <Link href="https://www.bosch.com/" target="_blank" rel="noopener noreferrer">
          <Image
            className="flex justify-center items-center object-scale-down h-10 w-fit"
            onMouseEnter={() => handleMouseEnter('/files/companies/bosch.webp')}
            onMouseLeave={() => handleMouseLeave('/files/companies/bosch.webp')}
            style={getImageStyle('/files/companies/bosch.webp')}
            priority={true}
            src="/files/companies/bosch.webp"
            alt="Logo"
            width={1000}
            height={1000}
          />
        </Link>

        <Link href="https://www.sanalabs.com/" target="_blank" rel="noopener noreferrer">
          <Image
            className="flex justify-center items-center object-scale-down h-10 w-fit"
            onMouseEnter={() => handleMouseEnter('/files/companies/sanalabs.webp')}
            onMouseLeave={() => handleMouseLeave('/files/companies/sanalabs.webp')}
            style={getImageStyle('/files/companies/sanalabs.webp')}
            priority={true}
            src="/files/companies/sanalabs.webp"
            alt="Logo"
            width={1000}
            height={1000}
          />
        </Link>

        <Link href="https://monarx.com/" target="_blank" rel="noopener noreferrer">
          <Image
            className="flex justify-center items-center object-scale-down h-10 w-fit"
            onMouseEnter={() => handleMouseEnter('/files/companies/monarx.webp')}
            onMouseLeave={() => handleMouseLeave('/files/companies/monarx.webp')}
            style={getImageStyle('/files/companies/monarx.webp')}
            src="/files/companies/monarx.webp"
            alt="Logo"
            width="1000"
            height="1000"
          />
        </Link>
      </div>
    </div>
  )
}
