import { ghost } from '@/clients/ghost'
import Footer from '@/components/navs/Footer'
import TopNav from '@/components/navs/top-nav'
import { Companies } from '@/components/page-specific/index/companies'
import { CTA } from '@/components/page-specific/index/cta'
import FAQSection from '@/components/page-specific/index/faq'
import { HeroCenter } from '@/components/page-specific/index/hero'
import { Benefits } from '@/components/page-specific/index/how-does-it-work'
import { KeyFeatures } from '@/components/page-specific/index/tools'
import { WhyChooseOpinly } from '@/components/page-specific/index/why-choose-opinly'
import { GhostBlogPostIds } from '@/utils/constants'
import { AnimatePresence } from 'framer-motion'
import { Activity } from 'lucide-react'
import type { GetStaticProps, InferGetStaticPropsType } from 'next'
import { NextSeo } from 'next-seo'
import React from 'react'

interface Props {
  post: Awaited<ReturnType<typeof ghost.posts.read>>
}

export const getStaticProps: GetStaticProps<Props> = async () => {
  const post = await ghost.posts.read({ id: GhostBlogPostIds['WHAT_IS_OPINLY'] })

  return {
    props: {
      post,
    },
  }
}

export default function Home({ post }: InferGetStaticPropsType<typeof getStaticProps>) {
  const ref = React.useRef(null)

  if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true') {
    return (
      <div className="min-h-screen flex flex-col items-center justify-center p-6 bg-zinc-50 ">
        <Activity className=" w-24 h-24 text-zinc-500  mb-8" />
        <h1 className="text-4xl font-bold text-center text-zinc-800  mb-4">
          We're down for maintenance
        </h1>
        <p className="text-lg text-center text-zinc-600  mb-8">
          We're currently updating our platform with some cool new features. We'll be back soon!
        </p>
        <div className="w-full max-w-md bg-zinc-200 rounded">
          <div
            className="h-2 bg-primary rounded"
            style={{
              width: '90%',
            }}
          />
        </div>
        <p className="text-xs text-center text-zinc-500 mt-4">Installation progress: 90%</p>
      </div>
    )
  }

  return (
    <>
      <NextSeo
        title="Opinly - Automated Competitor Analysis"
        description="Opinly is your ultimate software competitor analysis tool. Gain strategic insights with our comprehensive competitive analysis and real-time data tracking."
        themeColor="#ffffff"
      />
      <AnimatePresence>
        <div className="absolute inset-0 -z-10 h-fit w-full bg-">
          <div className="fixed w-full z-50	">
            <TopNav />
          </div>
          <div>
            <HeroCenter />
            <Companies />
            <KeyFeatures />
            <Benefits />
            <WhyChooseOpinly />
            <FAQSection />
            <CTA />
            <Footer />
          </div>
        </div>
      </AnimatePresence>
    </>
  )
}
