import React from 'react'

type FAQItem = {
  question: string
  answer: string
  linkText?: string
  linkHref?: string
}

const faqItems: FAQItem[] = [
  {
    question: 'How can I analyze my competitors?',
    answer:
      'To analyze your competitors, you can follow these steps: Identify Your Competitors, Gather Information using Opinly, Analyze Strengths and Weaknesses, Track Their Activities, and Evaluate Market Position.',
  },
  {
    question: 'What tool is used for competitor analysis?',
    answer:
      "The best tool for competitor analysis is Opinly. It provides comprehensive data and insights into your competitors' strategies, helping you make informed decisions to stay ahead in the market.",
  },
  {
    question: 'How to do software competitor analysis?',
    answer:
      'To perform software competitor analysis: Identify Key Competitors, Collect Data using Opinly, Compare Features and Pricing, Analyze Market Positioning, and Monitor Updates.',
  },
  {
    question: 'How to do competitive benchmarking?',
    answer:
      'Competitive benchmarking involves: Identifying Benchmarks, Selecting Competitors, Gathering Data using Opinly, Comparing Performance, and Implementing Changes.',
  },
  {
    question: 'What are the five forces of competitor analysis?',
    answer:
      'The five forces of competitor analysis are: Competitive Rivalry, Threat of New Entrants, Bargaining Power of Suppliers, Bargaining Power of Customers, and Threat of Substitute Products.',
  },
  {
    question: 'What are the benefits of conducting competitor analysis?',
    answer:
      'Conducting competitor analysis provides numerous benefits, including identifying market trends, uncovering market gaps, enhancing product offerings, improving marketing strategies, and better understanding customer needs. It enables businesses to strategically position themselves in the market by learning from the successes and failures of their competitors.',
  },
]

const FAQSection: React.FC = () => {
  return (
    <section className="bg-white">
      <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
        <h2 className="mb-8 text-4xl tracking-tight font-extrabold text-gray-900">
          Frequently asked questions
        </h2>
        <div className="grid pt-8 text-left border-t border-gray-200 md:gap-16 md:grid-cols-2">
          {faqItems.map((item, index) => (
            <div key={index} className="mb-10">
              <h3 className="flex items-center mb-4 text-md font-medium text-gray-900">
                {/* SVG icon */}
                <svg
                  className="flex-shrink-0 mr-2 w-5 h-5 text-gray-500"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                >
                  <path
                    fillRule="evenodd"
                    d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                    clipRule="evenodd"
                  ></path>
                </svg>
                {item.question}
              </h3>
              <p className="text-gray-500">
                {item.answer}
                {item.linkText && (
                  <a
                    href={item.linkHref}
                    className="font-medium underline text-primary-600 hover:no-underline"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {item.linkText}
                  </a>
                )}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default FAQSection
