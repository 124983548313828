import { useUser } from '@clerk/nextjs'
import Image from 'next/image'
import Link from 'next/link'

export default function Footer({}) {
  const { user } = useUser()
  const isSubscribed = user?.publicMetadata.isSubscribed
  const onboarded = user?.publicMetadata.onboarded

  const linkDest = () => {
    if (!user) {
      return '/'
    } else {
      if (!isSubscribed) {
        return '/'
      } else {
        if (!onboarded) {
          return '/onboarding'
        } else {
          return '/dashboard'
        }
      }
    }
  }

  return (
    <footer className="bg-white w-full mt-20">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <Link rel="canonical" href={linkDest()} className="w-fit mb-2">
              <Image
                className="object-scale-down h-8 w-44"
                priority={true}
                src="/files/logos/logo.webp"
                alt="Logo"
                width={1000}
                height={1000}
              />
            </Link>
            <p className="text-xs w-1/2 text-zinc-600 hidden sm:block ml-5 mt-2">
              Gain strategic insights and stay ahead in the SaaS market with Opinly's comprehensive
              competitor analysis software.
            </p>
          </div>

          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <div>
              <h2 className="mb-6 text-xs font-semibold text-gray-900 uppercase">Services </h2>
              <ul className="text-gray-500 font-medium">
                <li>
                  <Link href="/tools/competitive-price-analysis" className="hover:underline">
                    Price Analysis
                  </Link>
                </li>
                <li>
                  <Link href="/tools/product-comparison-tool" className="hover:underline">
                    Product Comparison
                  </Link>
                </li>
                <li>
                  <Link
                    href="/tools/competitor-landing-page-comparison"
                    className="hover:underline"
                  >
                    Landing Pages Comparison
                  </Link>
                </li>
                <li>
                  <Link href="/tools/seo-monitor" className="hover:underline">
                    SEO Monitor
                  </Link>
                </li>
                <li>
                  <Link href="/tools/competitor-email-monitoring" className="hover:underline">
                    Email Monitoring
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-xs font-semibold text-gray-900 uppercase">Follow us</h2>
              <ul className="text-gray-500 font-medium">
                <li>
                  <Link
                    href="https://www.linkedin.com/company/opinlyai/"
                    className="hover:underline "
                  >
                    LinkedIn
                  </Link>
                </li>
                <li>
                  <Link href="https://x.com/opinlyai" className="hover:underline">
                    Twitter
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://web.facebook.com/profile.php?id=61558739769277"
                    className="hover:underline "
                  >
                    Facebook
                  </Link>
                </li>
                <li>
                  <Link href="https://www.pinterest.com/opinlyai/" className="hover:underline">
                    Pinterest
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-xs font-semibold text-gray-900 uppercase">Legal</h2>
              <ul className="text-gray-500 font-medium">
                <li>
                  <Link href="/pricing" className="hover:underline">
                    Pricing
                  </Link>
                </li>
                <li>
                  <Link href="/privacy" className="hover:underline">
                    Privacy Policy
                  </Link>
                </li>
                <li>
                  <Link href="/terms-of-service" className="hover:underline">
                    Terms &amp; Conditions
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-xs text-gray-500 sm:text-center">
            © 2024 Davion Group Limited. All Rights Reserved.
          </span>
          <div className="flex mt-4 sm:justify-center sm:mt-0">
            <span className="text-xs text-gray-500 sm:text-center">
              Email us at <span className="hover:underline">support@opinly.ai</span>
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}
