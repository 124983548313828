import { HoverBorderGradient } from '@/components/ui/aceternity/hover-border-gradient'
import { Button } from '@/components/ui/button'
import Image from 'next/image'
import Link from 'next/link'

export const CTA = () => {
  return (
    <section className="text-white bg-teal-700 py-24 px-4 md:px-20">
      <div className="w-full md:w-5/6 mx-auto md:flex items-center ">
        <div className="md:w-2/3">
          <p className="text-2xl font-bold mb-4">Stay Ahead in the Market with Opinly</p>
          <p className="text-lg mt-4">
            Improve your product's standing or forge a distinct value proposition with our
            competitor analysis software, delivering the insight to succeed in the competitive
            market. Opinly grants you access to extensive data and tools, enhancing your marketing
            strategies to align with your target market's specific needs.
          </p>
        </div>
        <div className="ml-auto mt-8 md:mt-0">
          <Button
            analyticsKey="stay_ahead_cta"
            className=" text-md font-semibold"
            variant={'white'}
            size={'lg'}
            analyticsProperties={{
              section: 'public_site',
            }}
          >
            <Link href="/auth/signup">Register now</Link>
          </Button>
        </div>
      </div>
    </section>
  )
}
